import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaModalTitle } from "utils/schema";
import { Button } from "components/Button";
import Select from "react-select";
import {
  AFSCME_TITLE,
  TERM_LENGTH,
  MONTH_OFFCR_ELECTION,
  REQUIRED_FIELD,
} from "utils/constants";
import { addOffice } from "services/office";
import Loading from "components/Loading";

function ModalAddOfficerTitle({ data, closeModal, getDataOffice, aff_pk }) {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaModalTitle),
  });

  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const dataAFSCME_TITLE = AFSCME_TITLE.filter((item) => {
    return item.value.affiliate_title;
  });

  const [AFSCMETitle, setAFSCMETitle] = useState(null);
  const [termLength, setTermLength] = useState(null);
  const [monthElection, setMonthElection] = useState(null);

  const [isDisableSelect, setIsDisableSelect] = useState(true);

  const handleSelectAFSCMETitle = (select) => {
    setAFSCMETitle(select);
    setValue("affiliate_title", select.value.desc);
  };
  const handleSelectTermLength = (select) => {
    setTermLength(select);
  };
  const handleSelectMonthElection = (select) => {
    setMonthElection(select);
  };

  const handleChangeSelectMonth = (e) => {
    setIsDisableSelect(!e.target.checked);
  };

  const onSubmit = async (submitData) => {
    setIsSubmit(true);
    console.log("🚀 ===== submitData", submitData);
    console.log("🚀 ===== submitData", submitData.elected_position);
    if (termLength && AFSCMETitle) {
      setLoading(true);
      try {
        const is_elected = submitData.elected_position;
        const default_month_election = MONTH_OFFCR_ELECTION[0];

        await addOffice({
          data: [
            {
              aff_pk: data.aff_pk,
              aff_title: submitData.affiliate_title,
              title: AFSCMETitle.value.desc,
              title_cd: AFSCMETitle.value.code,
              auto_executive_board: submitData.auto_executive_board,
              seats: submitData.seats,
              term: termLength.value.desc,
              term_cd: termLength.value.code,
              month_of_election: is_elected
                ? monthElection.value.desc
                : default_month_election.value.desc,
              month_of_election_cd: is_elected
                ? monthElection.value.code
                : default_month_election.value.code,
              is_reporting_officer: submitData.reporting_officer,
              is_active_office: true,
              is_elected: is_elected,
              is_executive_board: submitData.executive_board_position,
            },
          ],
        });

        await getDataOffice(aff_pk);
        closeModal();
        setLoading(false);
      } catch (error) {
        console.log("error: ", error);
        setLoading(false);
      }
    }
  };

  const customStyles = {
    menuList: (provided, state) => {
      return {
        ...provided,
        maxHeight: 200,
      };
    },
  };

  return (
    <form className="" onSubmit={handleSubmit(onSubmit)}>
      <Loading isVisible={loading} />
      <div className="bg-slate-200/70 p-5 sm:px-10 rounded-b-md overflow-auto max-w-2xl">
        {/* "AFSCME Title" (dropdown with standard titles from common codes) */}
        <div className="relative sm:flex pb-3 sm:mb-1">
          <label htmlFor="AFSCME_Title" className="sm:min-w-[200px]">
            AFSCME Title:{" "}
          </label>
          <Select
            className="w-full text-sm"
            options={dataAFSCME_TITLE}
            onChange={handleSelectAFSCMETitle}
          />
          {!AFSCMETitle && isSubmit && (
            <p className="text-[11px] text-red-500 absolute right-0 -bottom-1">
              {REQUIRED_FIELD}
            </p>
          )}
        </div>

        {/* "Affiliate Title" (textbox, pre-populated with the selection from AFSCME Title) */}
        <div className="relative sm:flex pb-3 sm:mb-1">
          <label htmlFor="affiliate_title" className="sm:min-w-[200px]">
            Affiliate Title:{" "}
          </label>
          <input
            id="affiliate_title"
            className="border w-full sm:w-[350px] h-9 text-sm rounded-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("affiliate_title")}
          />
          {errors.affiliate_title && (
            <p className="text-[11px] text-red-500 absolute right-0 -bottom-1">
              {errors?.affiliate_title?.message}
            </p>
          )}
        </div>

        {/* "# of seats" (numeric selector, default = 1) */}
        <div className="relative sm:flex pb-3 sm:mb-1">
          <label htmlFor="seats" className="sm:min-w-[200px]">
            Seats:{" "}
          </label>
          <input
            id="seats"
            type="number"
            defaultValue={1}
            min={0}
            className="border w-full sm:w-[350px] h-9 text-sm rounded-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("seats")}
          />
          {errors.seats && (
            <p className="text-[11px] text-red-500 absolute right-0 -bottom-1">
              {errors?.seats?.message}
            </p>
          )}
        </div>

        {/* Length of Term */}
        <div className="relative sm:flex pb-3 sm:mb-1">
          <label htmlFor="length_of_term" className="sm:min-w-[200px]">
            Length of Term:{" "}
          </label>
          <Select
            className="w-full text-sm"
            options={TERM_LENGTH}
            onChange={handleSelectTermLength}
          />
          {!termLength && isSubmit && (
            <p className="text-[11px] text-red-500 absolute right-0 -bottom-1">
              {REQUIRED_FIELD}
            </p>
          )}
        </div>

        <div className="sm:flex items-center">
          {/* elected position */}
          <div className="relative flex pb-3 sm:mb-1">
            <label htmlFor="elected_position" className="min-w-[200px]">
              Elected position:{" "}
            </label>
            <input
              id="elected_position"
              type="checkbox"
              className="border w-6 h-6 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
              {...register("elected_position")}
              onChange={handleChangeSelectMonth}
            />
          </div>

          {/* month of election */}
          <div className="relative sm:flex pb-3 sm:mb-1 sm:flex-1 sm:pl-4 items-center">
            <label
              htmlFor="month_of_election"
              className={`sm:min-w-[150px] ${isDisableSelect && "opacity-70"}`}
            >
              Month of election:{" "}
            </label>
            <Select
              className="w-full text-sm"
              options={MONTH_OFFCR_ELECTION}
              styles={customStyles}
              onChange={handleSelectMonthElection}
              isDisabled={isDisableSelect}
            />
          </div>
        </div>

        {/* reporting officer */}
        <div className="relative flex pb-3 sm:mb-1">
          <label htmlFor="reporting_officer" className="min-w-[200px]">
            Reporting officer:{" "}
          </label>
          <input
            id="reporting_officer"
            type="checkbox"
            className="border w-6 h-6 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("reporting_officer")}
          />
        </div>

        {/* executive board position */}
        <div className="relative flex pb-3 sm:mb-1">
          <label htmlFor="executive_board_position" className="min-w-[200px]">
            Executive board position:{" "}
          </label>
          <input
            id="executive_board_position"
            type="checkbox"
            className="border w-6 h-6 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("executive_board_position")}
          />
        </div>

        {/* auto_executive_board  */}
        <div className="relative flex pb-3 sm:mb-1">
          <label htmlFor="auto_executive_board" className="min-w-[200px]">
            Auto Executive board:{" "}
          </label>
          <input
            id="auto_executive_board"
            type="checkbox"
            className="border w-6 h-6 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("auto_executive_board")}
          />
        </div>

        {/* Buttons */}
        <div className="flex justify-end space-x-8 mt-8">
          <div onClick={closeModal}>
            <Button text={"Cancel"} color={"bg-red-500"} />
          </div>
          <div>
            <Button text={"Submit"} color={"bg-blue-500"} />
          </div>
        </div>
      </div>
    </form>
  );
}

export default ModalAddOfficerTitle;
