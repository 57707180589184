import Layout from "components/layout";
import Loading from "components/Loading";
import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import ChangePassword from "./components/ChangePassword";
import ChooseRoles from "./components/ChooseRoles";

function Settings() {
  const [loading, setLoading] = useState(false);

  return (
    <Layout>
      <Loading isVisible={loading} />
      <ToastContainer />

      <div className="container mx-auto px-2 md:px-10">
        <div className="border-b mb-6 text-xl text-gray-500 flex">Settings</div>
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-8 px-2">
          {/* Change roles */}
          <ChooseRoles />
          {/*  ChangePassword */}
          <div className="col-span-2">
            <ChangePassword setLoading={setLoading} />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Settings;
