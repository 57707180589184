import CardRequest from "components/CardRequest";
import Layout from "components/layout";
import { useEffect, useState } from "react";
import { requestMessage } from "utils/constants";
import { ToastContainer } from "react-toastify";
import { notifySuccess, notifyError } from "utils/helper";
import {
  getPendingRequestAPI,
  responsePendingRequest,
} from "services/requestPending";
import Loading from "components/Loading";
import { DataIcon } from "assets/icons/Icons";

const RegistrationForm = () => {
  const [listPending, setListPending] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isRequest, setIsRequest] = useState(false);

  const getListPending = async () => {
    try {
      const list = [];
      setLoading(true);
      const response = await getPendingRequestAPI();

      if (response && response.data) {
        for (const property in response.data) {
          if (!Array.isArray(response.data[property]))
            list.push(response.data[property]);
        }
        setListPending(list);
      }

      setLoading(false);
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      console.log("error", error);
      setIsRequest(false);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = async (affiliates) => {
    const data = { accept: [affiliates] };
    setLoading(true);

    try {
      const response = await responsePendingRequest({ data });
      if (response) {
        notifySuccess(requestMessage.APPROVE_SUCCESS_MESSAGE);
        getListPending();
      }
      setLoading(false);
    } catch (error) {
      notifyError(requestMessage.APPROVE_ERROR_MESSAGE);
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeny = async (affiliates) => {
    const data = { deny: [affiliates] };
    setLoading(true);
    try {
      const response = await responsePendingRequest({ data });
      if (response) {
        notifySuccess(requestMessage.DENY_SUCCESS_MESSAGE);
        getListPending();
      }
      setLoading(false);
    } catch (error) {
      notifyError(requestMessage.DENY_ERROR_MESSAGE);
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getListPending();
  }, []);

  useEffect(() => {
    if (listPending.length > 0) {
      setIsRequest(true);
    } else {
      setIsRequest(false);
    }
  }, [listPending.length]);

  return (
    <Layout>
      <ToastContainer />
      <Loading isVisible={loading} />

      <div className="container m-auto w-full">
        {!isRequest ? (
          <>
            <div className="relative h-[80vh] flex justify-center items-center">
              <div className="text-sliver-10 flex flex-col justify-center items-center">
                <DataIcon size={64} />
                <p className="text-2xl">No pending requests</p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-3 2xl:px-10 my-6">
              {listPending.map((item, index) => {
                return (
                  <div key={index}>
                    <CardRequest
                      data={item}
                      handleApprove={() => handleApprove(item)}
                      handleDeny={() => handleDeny(item)}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default RegistrationForm;
