import { useState } from "react";
import Header from "./header";
import SideBar from "./sideBar";

const Layout = ({ children }) => {
  const [showSideBar, setShowSideBar] = useState(true);
  return (
    <div className="w-full bg-white flex overflow-hidden">
      <div className="fixed z-10 top-0 right-0 left-0">
        <Header showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      </div>

      <div
        className={`h-full z-10 fixed mt-16 ${
          !showSideBar && "-translate-x-64"
        } duration-300`}
      >
        <SideBar />
      </div>

      {showSideBar && <div className="md:ml-64"></div>}
      <main className="flex-1 h-full overflow-auto px-2 mt-20 duration-500">
        {children}
      </main>
    </div>
  );
};

export default Layout;
