import React from "react";
import { ToastContainer } from "react-toastify";
import logo from "assets/images/logo.png";
import { useForm } from "react-hook-form";
import Input from "components/Input";
import { useDispatch } from "react-redux";
import authActions from "redux/actions/auth";
import { Link, useNavigate } from "react-router-dom";
import { GreenButton } from "components/Button";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { notifyError, notifyInfo, notifySuccess } from "utils/helper";

function Form() {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isInputUser, setIsInputUser] = useState(false);

  const onSubmit = async ({ username, code, password, confirmPassword }) => {
    if (!isInputUser) {
      try {
        await Auth.forgotPassword(username);
        notifyInfo("Check your email inbox.");
        setIsInputUser(true);
      } catch (error) {
        notifyError(
          error?.message
            ? error.message
            : `Data Fetch Error : ${error.status} code`
        );
      }
    } else {
      if (confirmPassword === password) {
        try {
          await Auth.forgotPasswordSubmit(username, code, password);
          await Auth.signIn(username, password).then((user) => {
            console.log(user);
            dispatch(
              authActions.loginSuccess({
                isLoggedIn: true,
                email: user?.attributes?.email,
                name: user?.attributes?.name,
              })
            );
            notifySuccess("Your password is updated successfully!");
            setTimeout(() => {
              navigate("/login");
            }, 2000);
          });
        } catch (error) {
          notifyError(
            error?.message ? error.message : `Data Fetch Error ${error.status}`
          );
        }
      } else {
        notifyError(
          "Password and Confirm Password do not match. Please try again!"
        );
      }
    }
  };

  return (
    <div className="flex justify-center">
      <ToastContainer />
      <div className="absolute flex justify-center my-auto top-0 bottom-0 px-3 mx-auto h-[610px] w-full">
        <div className="bg-white px-5 py-10 drop-shadow-2xl rounded-sm sm:rounded-r-[0] w-[440px] min-w-[290px] max-w-[650px]">
          <div className="py-10 flex flex-col items-center">
            <img className="w-[262px] h-auto" src={logo} alt="logo" />
            <p className="text-gray-500 w-10/12 text-center text-sm sm:text-[1.1rem]">
              Officer Information Management System
            </p>
          </div>

          {/* login form */}
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <input
              autoComplete="off"
              name="hidden"
              type="text"
              className="hidden"
            />

            <div className="px-4">
              {!isInputUser ? (
                <div className="pb-8">
                  <p className="font-medium text-sm mb-1">Email</p>
                  <Input
                    type="text"
                    name="username"
                    register={register}
                    errors={errors}
                  />
                </div>
              ) : (
                <>
                  <div className="pb-4">
                    <p className="font-medium text-sm mb-1">Code</p>
                    <Input
                      type="text"
                      name="code"
                      register={register}
                      errors={errors}
                    />
                  </div>
                  <div className="pb-4">
                    <p className="font-medium text-sm mb-1">Password</p>
                    <Input
                      type="password"
                      name="password"
                      register={register}
                      errors={errors}
                    />
                  </div>

                  <div className="pb-4">
                    <p className="font-medium text-sm mb-1">Confirm password</p>
                    <Input
                      type="password"
                      name="confirmPassword"
                      register={register}
                      errors={errors}
                    />
                  </div>
                </>
              )}

              <div className="flex justify-center items-center font-san">
                <GreenButton
                  text={`${!isInputUser ? "Send code" : "Change password"}`}
                  // text={"Change password"}
                />
              </div>
              <div className="flex justify-center">
                <Link className="text-blue-500 text-sm py-3" to="/login">
                  Sign in
                </Link>
              </div>
            </div>
          </form>
        </div>

        <div className="hidden sm:block w-full max-w-[560px] drop-shadow-2xl">
          <div className="bg-slate-800-70 h-full flex justify-center items-center rounded-r-sm">
            <p className="text-white px-6 md:px-10 lg:px-12 sm:text-2xl md:text-3xl text-center">
              For people proudly committed to the public service that makes
              America happen, we never quit.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Form;
