import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import dayjs from "dayjs";

export const notifyError = (message) =>
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    theme: "colored",
  });

export const notifySuccess = (message) =>
  toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    theme: "colored",
  });

export const notifyInfo = (message) => {
  toast.info(message, {
    position: "top-right",
    autoClose: 5000,
    theme: "colored",
  });
};

export const formatDate = (value) => {
  const date = new Date(value);
  return date.toLocaleDateString();
  // return date.toLocaleDateString("en-US", { timeZone: "America/New_York" });
};
export const format10Date = (value) => {
  return new Date(value).toISOString().slice(0, 10).replace(/-/g, "");
};

export const excelDateToJSDate = (excelDate) => {
  if (excelDate) {
    if (typeof excelDate == "string" && isNaN(excelDate)) {
      const date = new Date(excelDate);
      return dayjs(date.toISOString().split("T")[0], "YYYY-MM-DD").format(
        "MM/DD/YYYY"
      );
    }
    const date = new Date(Math.round((excelDate - 25569) * 86400 * 1000));
    // format YYYY/MM/DD
    return dayjs(date.toISOString().split("T")[0], "YYYY-MM-DD").format(
      "MM/DD/YYYY"
    );
  }
  return;
};

export const matchTransform = (strInput) => {
  return strInput?.replace(/[^\w]/g, "")?.toLowerCase();
};
export const exportToExcel = ({ officers, authInfo, fileName }) => {
  console.log("🚀 ===== officers", officers);

  const ws2 = XLSX.utils.json_to_sheet([
    {
      "Reporter's Name": "Reporter's Email",
      [authInfo.name]: authInfo.email,
    },
    {
      "Reporter's Name": "Report Source",
      [authInfo.name]: "OIMS system",
    },
    {
      "Reporter's Name": "Report Date",
      [authInfo.name]: formatDate(new Date()),
    },
  ]);

  const data1 = officers.map((officer) => ({
    State: officer.state,
    "Affiliate Type(C,L,R,S)": officer?.type,
    "Council (or null)": officer?.council, //col 3
    Local: ["C"].includes(officer.type) ? "" : officer?.chapter,
    Subunit: officer?.sublocal, //col 5
    "Officer Title": officer?.aff_title, //col 6
    "Officer Name": `${officer?.first_nm} ${officer?.last_nm}`, //col 7
    "Enterprise ID": +officer?.person_pk, //col 8
    "Election Date": formatDate(officer?.pos_start_dt * 1000), //col 9
    "Expiration Date": formatDate(officer?.pos_expiration_dt * 1000), //col 10
    "Officer's Home Address": officer?.address?.Addr1, //col 11
    "Officer's Home Address2": officer?.address?.City, // col 12
    "Officer's Home Address3": officer?.address?.State, // col 13
    "Officer's Home Address4": officer?.address?.Zipcode, // col 14
    "Officer's Mailing Address (if different)": "", // col 15
    "Officer's Mailing Address (if different)1": "", // col 16
    "Officer's Mailing Address (if different)2": "", // col 17
    "Officer's Mailing Address (if different)3": "", // col 18
    "Send Mail to Affiliate Office instead of officer's home?(Y or N)": "", //col 19
    "Primary Phone Number": officer?.phone, //col 20
    "Primary Phone Type": "Cell", //col 21
    "Secondary Phone Number": "", //col 22
    "Secondary Phone Type": "", //col 23
  }));

  const ws = XLSX.utils.json_to_sheet(data1);

  const wb = {
    Sheets: { sheet1: ws, sheet2: ws2 },
    SheetNames: ["sheet1", "sheet2"],
  };

  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

// Map RHF's dirtyFields over the `data` received by `handleSubmit` and return the changed subset of that data.
export function dirtyValues(dirtyFields, allValues) {
  // If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
  // "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;
  // Here, we have an object
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      dirtyValues(dirtyFields[key], allValues[key]),
    ])
  );
}

export const exportToCSV = (data, fileName) => {
  const dataExport = data.map((item) => ({
    afscme_title_nm: item?.title?.title_cd,
    aff_pk: item?.aff_pk,
    position_mbr_affiliation: item?.position_mbr_affiliation_cd,
    length_of_term: item?.length_of_term?.code,
    max_number_in_office: item?.office?.seats,
    month_of_election: item?.month_of_election?.code,
    current_term_end: item?.current_term_end,
    active_fg: item?.active_fg ? 1 : 0,
    person_pk: item?.person_pk,
    pos_start_dt: item?.pos_start_dt
      ? format10Date(item?.pos_start_dt * 1000)
      : "",
    pos_end_dt: item?.pos_end_dt ? format10Date(item?.pos_end_dt * 1000) : "",
    pos_expiration_dt: item?.pos_expiration_dt
      ? format10Date(item?.pos_expiration_dt * 1000)
      : "",
    suspended_fg: item?.suspended_fg ? 1 : 0,
    suspended_dt: item?.suspended_dt
      ? format10Date(item?.suspended_dt * 1000)
      : "",
    aff_councilRetiree_chap: item?.affiliate?.council,
    aff_localSubChapter: item?.affiliate?.local?.toString()?.padStart(4, "0"),
    aff_subunit: item?.affiliate?.subunit,
    auto_delegate_prvsn_fg: item?.office?.automatic_delegate ? 1 : 0,
    Aff_type: item?.affiliate?.type,
    reporting_officer_fg: item?.reporting_officer_fg ? 1 : 0,
    Addr1: item?.address?.Addr1,
    Addr2: item?.address?.Addr2,
    City: item?.address?.City,
    State: item?.address?.State,
    Zipcode: item?.address?.Zipcode,
    Zip_Plus: item?.address?.Zip_Plus,
    country: item?.country_cd ? item?.country_cd : 9001,
    county: item?.county,
    last_modified_date: item?.last_modified_date
      ? format10Date(item?.last_modified_date * 1000)
      : "",
  }));

  const ws = XLSX.utils.json_to_sheet(dataExport);

  const wb = {
    Sheets: { sheet1: ws },
    SheetNames: ["sheet1"],
  };

  XLSX.writeFile(wb, `${fileName}.txt`, { bookType: "csv", FS: "\t" });
};

export const checkRequired = (item) => {
  if (
    item?.state &&
    item.aff_type &&
    ((item.aff_type !== "C" && item.local) || item.aff_type === "C") &&
    item.officer_title &&
    item.officer_name &&
    item.enterprise_ID &&
    item.election_date
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkHomeAddress = (item) => {
  if (
    Object.values(item.home_address).includes(undefined) ||
    Object.values(item.home_address).includes("")
  ) {
    return false;
  } else {
    return true;
  }
};

export const checkMailingAddress = (item) => {
  if (
    Object.values(item.mailing_address).includes(undefined) ||
    Object.values(item.mailing_address).includes("")
  ) {
    return false;
  } else {
    return true;
  }
};
export const checkErrorField = (officer, field) => {
  return officer?.errors?.some((el) => el.column === field);
};
export const getShortErrorMessage = (officer, field) => {
  return officer?.errors?.filter((el) => el.column === field)?.[0]
    ?.shortMessage;
};

export const preCheckField = (officer) => {
  const isHomeAddress = checkHomeAddress(officer);
  const isMailingAddress = checkMailingAddress(officer);

  let errors = [];

  if (!officer.state) {
    errors.push({
      message: "Missing State",
      shortMessage: "Missing State",
      column: "state",
    });
  }

  if (
    !officer.aff_type &&
    !(
      (officer.aff_type?.toLowerCase() !== "c" && officer.local) ||
      officer.aff_type?.toLowerCase() === "c"
    )
  ) {
    errors.push({
      message: "Missing Type",
      shortMessage: "Missing Type",
      column: "aff_type",
    });
  }

  if (!officer.officer_title) {
    errors.push({
      message: "Missing Title",
      shortMessage: "Missing Title",
      column: "officer_title",
    });
  }
  if (!officer.officer_name) {
    errors.push({
      message: "Missing Name",
      shortMessage: "Missing Name",
      column: "officer_name",
    });
  }
  if (!officer.enterprise_ID) {
    errors.push({
      message: "Missing Enterprise ID",
      shortMessage: "Missing Enterprise ID",
      column: "enterprise_ID",
    });
  }
  if (!officer.election_date) {
    errors.push({
      message: "Missing Start Date",
      shortMessage: "Missing Start Date",
      column: "election_date",
    });
  }
  if (!isHomeAddress && !isMailingAddress) {
    errors.push({
      message: "Missing Address Information",
      shortMessage: "Missing Address Information",
      column: "address",
    });
  }
  return errors;
};
