import { Button } from "components/Button";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { editModalExcel } from "utils/schema";

function EditOfficerModal({ officers, closeModal, dataEdit, setDataOfficer }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm({ resolver: yupResolver(editModalExcel) });

  const onSave = async (submitData) => {
    console.log("🚀 ===== submitData", dataEdit.id);
    const officeIndex = officers.findIndex((item) => {
      return item.id === dataEdit.id;
    });
    console.log(officeIndex);
    officers[officeIndex] = submitData;
    console.log(officers);
    setDataOfficer([...officers]);
    closeModal();
  };

  const setValueInput = () => {
    setValue("state", dataEdit?.state);
    setValue("aff_type", dataEdit?.aff_type);
    setValue("council", dataEdit?.council);
    setValue("local", dataEdit?.local);
    setValue("subunit", dataEdit?.subunit);
    setValue("officer_title", dataEdit?.officer_title);
    setValue("officer_name", dataEdit?.officer_name);
    setValue("enterprise_ID", dataEdit?.enterprise_ID);

    setValue("election_date", dataEdit?.election_date);
    setValue("expiration_date", dataEdit?.expiration_date);
    setValue("home_address.Street", dataEdit?.home_address.Street);
    setValue("home_address.City", dataEdit?.home_address.City);
    setValue("home_address.State", dataEdit?.home_address.State);
    setValue("home_address.Zip", dataEdit?.home_address.Zip);

    setValue("mailing_address.Street", dataEdit?.mailing_address.Street);
    setValue("mailing_address.City", dataEdit?.mailing_address.City);
    setValue("mailing_address.State", dataEdit?.mailing_address.State);
    setValue("mailing_address.Zip", dataEdit?.mailing_address.Zip);

    setValue("send_mail", dataEdit?.send_mail);

    setValue("primary_phone_number", dataEdit?.primary_phone_number);
    setValue("primary_phone_type", dataEdit?.primary_phone_type);
    setValue("secondary_phone_number", dataEdit?.secondary_phone_number);
    setValue("secondary_phone_type", dataEdit?.secondary_phone_type);
  };

  useEffect(() => {
    setValueInput();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSave)} autoComplete="off">
      <div className="bg-slate-200/70 p-5 sm:px-10 rounded-b-md overflow-auto max-w-2xl">
        <div className="sm:flex pb-3">
          <p className="sm:min-w-[200px]">State:</p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("state")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[200px]">Affiliate Type(C,L,R,S):</p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("aff_type")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[200px]">Council (or null) :</p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("council")}
          />
        </div>
        <div className="sm:flex pb-3">
          <p className="sm:min-w-[200px]">Local:</p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("local")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[200px]">Subunit:</p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("subunit")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[200px]">Officer Title:</p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("officer_title")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[200px]">Officer Name:</p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("officer_name")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[200px]">Enterprise ID:</p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("enterprise_ID")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[200px]">Election Date:</p>
          <input
            type="date"
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("election_date")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[200px]">Expiration Date:</p>
          <input
            type="date"
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("expiration_date")}
          />
        </div>

        {/* address1 */}
        <div className="flex mt-3">
          <div className="sm:min-w-[200px]"></div>
          <p className="font-bold mb-1">Officer's Home Address</p>
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[200px]">Street: </p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            autoComplete="new-user-street-address"
            {...register("home_address.Street")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[200px]">City: </p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            autoComplete="off"
            {...register("home_address.City")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[200px]">State: </p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            autoComplete="off"
            {...register("home_address.State")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[200px]">Zipcode: </p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            autoComplete="off"
            {...register("home_address.Zip")}
          />
        </div>

        {/* address2 */}
        <div className="flex mt-3">
          <div className="sm:min-w-[200px]"></div>
          <p className="font-bold mb-1">
            Officer's Mailing Address (if different)
          </p>
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[200px]">Street: </p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            autoComplete="new-user-street-address"
            {...register("mailing_address.Street")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[200px]">City: </p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            autoComplete="off"
            {...register("mailing_address.City")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[200px]">State: </p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            autoComplete="off"
            {...register("mailing_address.State")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[200px]">Zipcode: </p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            autoComplete="off"
            {...register("mailing_address.Zip")}
          />
        </div>

        <div className="sm:flex sm:text-xs pb-3 text-sm">
          <p className="sm:max-w-[200px]">
            Send Mail to Affiliate Office instead of officer's home?(Y or N):
          </p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("send_mail")}
          />
        </div>

        <div className="sm:flex py-3">
          <p className="sm:min-w-[200px]">Primary Phone:</p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("primary_phone_number")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[200px]">Primary Phone Type:</p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("primary_phone_type")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[200px]">Secondary Phone:</p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("secondary_phone_number")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[200px]">Secondary Phone Type:</p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("secondary_phone_type")}
          />
        </div>
        <div className="flex justify-end space-x-8 mt-4">
          <div onClick={closeModal}>
            <Button text={"Cancel"} color={"bg-red-500"} />
          </div>
          <button
            type="submit"
            className={`bg-blue-500 py-1 sm:py-1.5 px-4 text-white rounded-md text-sm min-w-[90px] hover:opacity-80 shadow-lg duration-500`}
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}

export default EditOfficerModal;
