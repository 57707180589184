import React, { useState } from "react";
import { DisableButton, GreenButton } from "components/Button";
import Input from "components/Input";
import logo from "assets/images/logo.png";
import { useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { schemaNewPassword } from "utils/schema";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import authActions from "redux/actions/auth";
import { notifyError, notifySuccess } from "utils/helper";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Form() {
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const dispatch = useDispatch();

  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaNewPassword),
  });

  const onSubmit = async ({ newPassword, newPasswordVerify }) => {
    if (newPassword === newPasswordVerify) {
      if (newPassword.length > 12) {
        try {
          setLoading(true);
          Auth.signIn(query.get("u").toLowerCase(), query.get("v"))
            .then((user) => {
              if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                Auth.completeNewPassword(user, newPassword)
                  .then((user) => {
                    dispatch(
                      authActions.loginSuccess({
                        isLoggedIn: true,
                        email: user?.challengeParam?.userAttributes?.email,
                        name: user?.challengeParam?.userAttributes?.name,
                      })
                    );
                    notifySuccess("Your password is updated successfully");
                    setLoading(false);
                    setTimeout(() => {
                      navigate("/login");
                    }, 2000);
                  })
                  .catch((err) => {
                    notifyError(err.message);
                    setLoading(false);
                  });
              }
            })
            .catch((err) => {
              setLoading(false);
              notifyError(
                err?.message ? err.message : `Data Fetch Error ${err.status}`
              );
            });
        } catch (err) {
          console.log("🚀 ~ file: Form.js ~ line 87 ~ onSubmit ~ err", err);
          notifyError(
            err?.message ? err.message : `Data Fetch Error ${err.status}`
          );
        }
      } else {
        notifyError("Password is not long enough");
      }
    } else {
      notifyError(
        "Password and Confirm Password do not match, Please try again"
      );
    }
  };

  return (
    <div className="flex justify-center">
      <ToastContainer />
      <div className="absolute flex justify-center my-auto top-0 bottom-0 px-3 mx-auto h-[610px] w-full">
        <div className="bg-white px-5 py-10 drop-shadow-2xl rounded-sm sm:rounded-r-[0] w-[440px] min-w-[290px] max-w-[650px]">
          <div className="flex flex-col items-center py-10">
            <img className="w-[262px] h-auto" src={logo} alt="logo" />
            <p className="text-gray-500 w-10/12 text-center text-sm sm:text-[1.1rem]">
              Officer Information Management System
            </p>
          </div>

          {/* login form */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="px-4">
              <div className="pb-4">
                <p className="mb-1 text-sm font-medium">New password</p>
                <Input
                  type="password"
                  name="newPassword"
                  register={register}
                  errors={errors}
                />
              </div>

              <div className="pb-4">
                <p className="mb-1 text-sm font-medium">Confirm Password</p>
                <Input
                  type="password"
                  name="newPasswordVerify"
                  register={register}
                  errors={errors}
                />
              </div>

              <div className="flex items-center justify-center font-san">
                {loading ? (
                  <DisableButton text="Please wait" />
                ) : (
                  <GreenButton text="Update Password" />
                )}
              </div>
            </div>
          </form>
        </div>

        <div className="hidden sm:block w-full max-w-[560px] drop-shadow-2xl">
          <div className="flex items-center justify-center h-full rounded-r-sm bg-slate-800-70">
            <p className="px-6 text-center text-white md:px-10 lg:px-12 sm:text-2xl md:text-3xl">
              For people proudly committed to the public service that makes
              America happen, we never quit.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
