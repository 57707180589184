import { combineReducers } from "redux";
import { aff_pkReducer } from "./aff_pk";
import { authReducer } from "./auth";

const rootReducer = combineReducers({
  auth: authReducer,
  aff_pk: aff_pkReducer,
});

export default rootReducer;
