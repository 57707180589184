export const AFFILIATE_STATE_LIST =
  "https://za7ovakvwl.execute-api.us-east-1.amazonaws.com/dev/v1/list-affiliates";

export const REQUEST_USER_ACCESS =
  "https://za7ovakvwl.execute-api.us-east-1.amazonaws.com/dev/v1/new-access-request";

export const PENDING_REQUEST_LIST =
  "https://za7ovakvwl.execute-api.us-east-1.amazonaws.com/dev/v1/query-access-requests";

export const RESPONSE_PENDING_REQUEST =
  "https://za7ovakvwl.execute-api.us-east-1.amazonaws.com/dev/v1/dispose-access-request";

export const OFFICER_RECORD =
  // "https://73lf8cplu6.execute-api.us-east-1.amazonaws.com/default/OIMS-List-Of-Officer";
  "https://za7ovakvwl.execute-api.us-east-1.amazonaws.com/dev/v1/query-officers";

export const TREE_HIERARCHY =
  "https://za7ovakvwl.execute-api.us-east-1.amazonaws.com/dev/v1/list-affiliates";

export const EDIT_OFFICER =
  "https://za7ovakvwl.execute-api.us-east-1.amazonaws.com/dev/v1/mutate-officers";

export const LIST_ADD_OFFICER =
  // "https://r7z7xmgd2b.execute-api.us-east-1.amazonaws.com/default/test_f2";
  "https://za7ovakvwl.execute-api.us-east-1.amazonaws.com/dev/v1/query-eligible-members";

export const OFFICER_TRANSMITTAL =
  "https://za7ovakvwl.execute-api.us-east-1.amazonaws.com/dev/v1/query-officer-transmittal";

export const FIND_AFF_PK =
  "https://za7ovakvwl.execute-api.us-east-1.amazonaws.com/dev/v1/get-aff_pk-for-affiliate";

export const EDIT_OFFICE =
  "https://za7ovakvwl.execute-api.us-east-1.amazonaws.com/dev/v1/mutate-offices";
