import { Button } from "components/Button";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import Select from "react-select";
import { addOfficer, getListAddOfficer } from "services/officer";
import Loading from "components/Loading";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaAddOfficer } from "utils/schema";
import { notifyError } from "utils/helper";
import debounce from "lodash/debounce";

function AddModal({ data, closeModal, getDataOfficer, setDataOffices }) {
  console.log("🚀 ===== data", data);
  const { register, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schemaAddOfficer),
  });

  const [loading, setLoading] = useState(false);
  const [loadingSelect, setLoadingSelect] = useState(false);

  const [dataSelect, setDataSelect] = useState([]);

  const onSubmit = async (submitData) => {
    console.log(data);
    console.log(submitData);
    setLoading(true);
    const sData = {
      ...submitData,
      address: {
        addr1: submitData?.address?.addr1,
        addr2: submitData?.address?.addr2,
        city: submitData?.address?.city,
        state: submitData?.address?.state,
        zipcode: submitData?.address?.zipcode,
      },
      aff_pk: data.aff_pk,
      office_uuid: data.office_uuid,
      pos_expiration_dt: dayjs(submitData.pos_expiration_dt).unix(),
      pos_end_dt: dayjs(submitData.pos_end_dt).unix(),
      pos_start_dt: dayjs(submitData.pos_start_dt).unix(),
      suspended_dt: dayjs(submitData.suspended_dt).unix(),
    };
    console.log("🚀 ===== data", data);

    try {
      await addOfficer({
        data: [sData],
      });
      getDataOfficer();
      closeModal();
      setLoading(false);
    } catch (error) {
      console.log("🚀 ===== error", error);
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      setLoading(false);
    }
  };

  const getListAdd = async (dataQuery) => {
    console.log("🚀 ===== data", data);
    setLoadingSelect(true);
    try {
      const response = await getListAddOfficer({
        data: dataQuery,
      });

      const dataOffices = response.data;

      const filterExistOfficer = dataOffices.filter((item) => {
        return !data.existOfficers.some(
          (exist) => item.person_pk === exist.person_pk
        );
      });

      setDataSelect(filterExistOfficer);
      setLoadingSelect(false);
    } catch (error) {
      console.log("Error: ", error);
      setLoadingSelect(false);
    }
  };

  useEffect(() => {
    getListAdd({
      aff_pk: data.aff_pk,
      query: "",
      from: 0,
      size: 20,
    });
    return () => {};
  }, [data.aff_pk]);

  const debounceGetDataSelect = debounce((value) => {
    getListAdd({ aff_pk: data.aff_pk, query: value, from: 0, size: 20 });
  }, 1000);

  const inputChange = useCallback(
    (value) => {
      if (value !== "") {
        debounceGetDataSelect(value);
      }
    },
    [debounceGetDataSelect]
  );

  const getPos_expiration_dt = (length_of_term) => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + length_of_term);
    return date.toISOString().slice(0, 10);
  };

  const handleSelectOfficer = (value) => {
    console.log("🚀 ===== value", value);
    const currentDate = new Date().toISOString().slice(0, 10);

    setValue("office_uuid", value.value.office_uuid);
    setValue("person_pk", value.value.person_pk);
    setValue(
      "position_mbr_affiliation_cd",
      value.value.position_mbr_affiliation_cd
    );
    setValue("first_nm", value.value.first_nm);
    setValue("last_nm", value.value.last_nm);

    setValue("pos_start_dt", currentDate);

    let expiration_dt = null;
    switch (data.length_of_term) {
      case "1 years":
        expiration_dt = getPos_expiration_dt(1);
        break;
      case "2 years":
        expiration_dt = getPos_expiration_dt(2);
        break;
      case "3 years":
        expiration_dt = getPos_expiration_dt(3);
        break;
      case "4 years":
        expiration_dt = getPos_expiration_dt(4);
        break;
      default:
        expiration_dt = null;
        break;
    }
    setValue("pos_expiration_dt", expiration_dt);

    setValue("reporting_officer_fg", value?.value?.reporting_officer_fg);
    setValue("address.addr1", value?.value?.address?.addr1);
    setValue("address.addr2", value?.value?.address?.addr2);
    setValue("address.city", value?.value?.address?.city);
    setValue("address.state", value?.value?.address?.state);
    setValue("address.zipcode", value?.value?.address?.zipcode);
    setValue("phone", value?.value?.phone);
    setValue("person_pk", value?.value?.person_pk);
    setValue("aff_pk", value?.value?.aff_pk);
    setValue(
      "position_mbr_affiliation_cd",
      value?.value?.position_mbr_affiliation_cd
    );
  };

  const options = dataSelect.map((item) => {
    return {
      value: item,
      label: (
        <div className="">
          <p>Name: {`${item?.first_nm} ${item?.last_nm}`}</p>
          <p>Member ID: {item?.person_pk}</p>
          <p>
            Address: {item?.address?.addr1 && `${item?.address?.addr1},`}{" "}
            {item?.address?.city && `${item?.address?.city},`}{" "}
            {item?.address?.state && `${item?.address?.state},`}{" "}
            {item?.address?.zipcode && `${item?.address?.zipcode},`}
          </p>
          <p>Affiliate:{item?.active_memberships[0]?.short_name} </p>
        </div>
      ),
    };
  });

  const filterOption = () => true;

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      cursor: "pointer",
    }),
    control: (styles) => ({
      ...styles,
      cursor: "text",
    }),
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Loading isVisible={loading} />
      <input autoComplete="off" name="hidden" type="text" className="hidden" />

      <div className="bg-slate-200/70 p-5 sm:px-10 rounded-b-md overflow-auto max-w-2xl">
        <div className="mb-4">
          <Select
            value=""
            styles={customStyles}
            options={options}
            onChange={handleSelectOfficer}
            placeholder={<div>Search</div>}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            isLoading={loadingSelect}
            filterOption={filterOption}
            onInputChange={inputChange}
          />
        </div>

        <input className="hidden" {...register("office_uuid")} />
        <input
          className="hidden"
          {...register("position_mbr_affiliation_cd")}
        />
        <input className="hidden" {...register("person_pk")} />

        <input
          className="hidden"
          {...register("position_mbr_affiliation_cd")}
        />

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">First Name:</p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            disabled
            {...register("first_nm")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">Last Name:</p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            disabled
            {...register("last_nm")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">Member ID:</p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            disabled
            {...register("person_pk")}
          />
        </div>
        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">Start:</p>
          <input
            type="date"
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("pos_start_dt")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">End:</p>
          <input
            type="date"
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("pos_end_dt")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">Expiration:</p>
          <input
            type="date"
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("pos_expiration_dt")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">Suspended:</p>
          <input
            type="date"
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("suspended_dt")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">Address1: </p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            autoComplete="new-user-street-address"
            {...register("address.addr1")}
          />
        </div>
        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">Address2: </p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            autoComplete="off"
            {...register("address.addr2")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">City: </p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            autoComplete="off"
            {...register("address.city")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">State: </p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            autoComplete="off"
            {...register("address.state")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">Zipcode: </p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            autoComplete="off"
            {...register("address.zipcode")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">Phone:</p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("phone")}
          />
        </div>

        <div className="flex justify-end space-x-8 mt-4">
          <div onClick={closeModal}>
            <Button text={"Cancel"} color={"bg-red-500"} />
          </div>
          <button
            type="submit"
            className={`bg-blue-500 py-1 sm:py-1.5 px-4 text-white rounded-md text-sm min-w-[90px] hover:opacity-80 shadow-lg duration-500`}
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );
}

export default memo(AddModal);
