import axios from "axios";
import { AFFILIATE_STATE_LIST, REQUEST_USER_ACCESS } from "utils/request";

export const fetchStateAPI = () => {
  return axios.post(AFFILIATE_STATE_LIST);
};

export const fetchAffliatessAPI = ({ data }) => {
  return axios.post(AFFILIATE_STATE_LIST, data);
};

export const fetchSelectSublocalAPI = ({ data }) => {
  return axios.post(AFFILIATE_STATE_LIST, data);
};

export const reigsterUserData = ({ data }) => {
  return axios.post(REQUEST_USER_ACCESS, data);
};
