import axios from "services/setupAxios";
import { EDIT_OFFICE } from "utils/request";

export const editOffice = ({ data }) => {
  return axios.post(EDIT_OFFICE, data);
};

export const addOffice = ({ data }) => {
  return axios.post(EDIT_OFFICE, data);
};
