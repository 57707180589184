/** @format */

import { Button } from "components/Button";
import Layout from "components/layout";
import Loading from "components/Loading";
import Modal from "components/Modal";
import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ModalUploadFile from "./Components/ModalUploadFile";
import TableOfficer from "./Components/TableOfficer";
import {
  addOfficer,
  getAff_pk,
  getOffices,
  editOfficer,
  getPosition,
} from "services/officer";

import { matchTransform, preCheckField } from "utils/helper";
import dayjs from "dayjs";

function ImportOfficer() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(true);
  const closeUploadModal = () => setShowUploadModal(false);

  const [dataOfficer, setDataOfficer] = useState([]);

  const [isSubmit, setIsSubmit] = useState(false);

  const handleBack = () => {
    navigate(`/user`);
  };

  const handleImport = () => {
    setShowUploadModal(true);
  };

  const handleSubmit = useCallback(
    async (officer, status) => {
      let cloneDataOfficer = dataOfficer.slice();
      let isSubmitted = false;

      try {
        if (status) setLoading(true);
        if (officer.needs_insert) {
          await addOfficer({
            data: [officer.data],
          });
          isSubmitted = true;
        }
        if (officer.needs_update) {
          await editOfficer({
            data: [officer.data],
          });
          isSubmitted = true;
        }
        return false;
      } catch (error) {
        console.log(error);
      } finally {
        if (isSubmitted) {
          cloneDataOfficer.forEach((element, index) => {
            if (element.id === officer.id) {
              cloneDataOfficer[index].isSubmit = true;
            }
          });
        }

        if (status) setLoading(false);
      }
    },
    [dataOfficer]
  );
  const match_title = (row, offices) => {
    let title = matchTransform(row?.officer_title);
    let possible_titles =title?  new Set([
      title,
      title.replaceAll("local", ""),
      title.replaceAll("council", ""),
    ]) : new Set([]);
    return offices?.filter((o) =>
      [o.aff_title, o.title].some((t) => possible_titles.has(matchTransform(t)))
    );
  };
  const handleLoadingFile = useCallback(async (dataCorrect, status) => {
    try {
      if (status) setLoading(true);

      let cloneDataOfficer = dataCorrect.slice();
      let Aff_pkCache = new Map();
      let OfficesCache = new Map();

      const getOfficesCached = (affpk) => {
        if (!OfficesCache.has(affpk))
          OfficesCache.set(affpk, getOffices(affpk));
        return OfficesCache.get(affpk);
      };

      const getAffPkCache = (councilData) => {
        if (!Aff_pkCache.has(JSON.stringify(councilData)))
          Aff_pkCache.set(
            JSON.stringify(councilData),
            getAff_pk({ data: [councilData] })
          );
        return Aff_pkCache.get(JSON.stringify(councilData));
      };

      const promises = cloneDataOfficer.map(async (officer, index) => {
        let address = {};
        officer.id = index;
        officer.errors = preCheckField(officer);
        if (officer.isSubmit) {
          return officer;
        }

        const response = await getAffPkCache({
          state: officer.state,
          type: officer.aff_type,
          council: +officer.council,
          local: +officer.local,
          subunit: officer.subunit,
        });

        const affPk = response.data[0]?.aff_pk;
        if (!affPk) {
          officer.errors.push({
            column: "aff_type",
            shortMessage: "No Such Affiliate",
            message: `Unable to find affiliate matching ${officer.state}/${officer.council}/${officer.local}/${officer.subunit}`,
          });
        }
        const offices = await getOfficesCached(affPk);
        const office_match = match_title(officer, offices);

        if (
          (!office_match || office_match.length <= 0) &&
          affPk &&
          officer.officer_title
        ) {
          officer.errors.push({
            column: "officer_title",
            shortMessage: "No Such Position",
            message: `Unable to locate position matching ${officer.officer_title}`,
          });
        }

        switch (officer.send_mail) {
          case "Y":
            address = {
              Addr1:
                officer.mailing_address?.Street || officer.home_address?.Street,
              City: officer.mailing_address?.City || officer.home_address?.City,
              State:
                officer.mailing_address?.State || officer.home_address?.State,
              Zipcode:
                officer.mailing_address?.Zip || officer?.home_address?.Zip,
            };
            break;

          case "N":
            address = {
              Addr1:
                officer.home_address?.Street || officer.mailing_address?.Street,
              City: officer.home_address?.City || officer.mailing_address?.City,
              State:
                officer.home_address?.State || officer.mailing_address?.State,
              Zipcode:
                officer.home_address?.Zip || officer?.mailing_address?.Zip,
            };
            break;

          default:
            address = {
              Addr1: officer.home_address?.Street,
              City: officer.home_address?.City,
              State: officer.home_address?.State,
              Zipcode: officer.home_address?.Zip,
            };
            break;
        }

        //same data checking and update data checking

        const existing_officer = office_match
          ?.flatMap?.((ofc) => ofc.officers.map((o) => ({ ...o, office: ofc })))
          ?.filter?.(
            (o) =>
              `${o.person_pk}` === `${officer.enterprise_ID}` &&
              Date(o.pos_start_dt * 1000) >= Date(officer.election_date)
          )
          ?.sort?.((a, b) => a.pos_start_dt - b.pos_start_dt)?.[0];
        let filterInsertOfficer = {};

        if (affPk && officer.enterprise_ID) {
          const positionMbrs = await getPosition({
            aff_pk: affPk,
            from: 0,
            query: officer.enterprise_ID + "",
            size: 20,
          });
          filterInsertOfficer = positionMbrs?.data?.filter((item) => {
            return +item.person_pk === +officer.enterprise_ID;
          })[0];
        }
        if (!filterInsertOfficer) {
          officer.errors = [
            ...officer.errors,
            {
              message: "Member not eligible for this office",
              shortMessage: "Not Eligible",
              column: "enterprise_ID",
            },
          ];
        }
        if (existing_officer) {
          const needs_update = !(
            `${officer.enterprise_ID}` === `${existing_officer.person_pk}` &&
            officer.officer_name ===
              `${existing_officer.first_nm} ${existing_officer.last_nm}` &&
            dayjs(officer.expiration_date).unix() ===
              existing_officer.pos_expiration_dt &&
            address.Addr1 === existing_officer.address.Addr1 &&
            address.City === existing_officer.address.City &&
            address.State === existing_officer.address.State &&
            address.Zipcode === existing_officer.address.Zipcode
          );
          if (needs_update) {
            officer.needs_update = officer.errors.length ? false : needs_update;
            officer.data = officer.errors.length ? {}:{
              // first_nm: officer.officer_name.match(/^(\S+)\s(.*)/).slice(1)[0],
              // last_nm: officer.officer_name.match(/^(\S+)\s(.*)/).slice(1)[1],
              address: {
                ...address,
              },
              position_mbr_affiliation_cd:
                existing_officer.position_mbr_affiliation_cd,
              aff_pk: affPk,
              uuid: existing_officer.uuid,
              pos_expiration_dt: dayjs(officer.expiration_date).unix(),
              person_pk: officer.enterprise_ID,
            };
            // return officer;
          }
          if (!needs_update && !officer.errors.length) {
            officer.isSubmit = true;
          }
          return officer;
        } else {
          const office = office_match?.sort?.(
            (a, b) =>
              b.seats - b.officers.length - (a.seats - a.officers.length)
          )?.[0]; // office with most seats

          if ((!office || office.seats - office.officers.length < 1) && office_match?.length > 0) {
            officer.errors = [
              ...officer.errors,
              {
                message: "No Available Seat",
                shortMessage: "No Available Seat",
                column: "officer_title",
              },
            ];
          }

          // find officer in list test_f2 to add

          officer.needs_insert = officer.errors.length ? false : true;
          officer.data = officer.errors.length ? {}:{
            first_nm: filterInsertOfficer?.first_nm,
            last_nm: filterInsertOfficer?.last_nm,
            address: {
              ...address,
            },
            position_mbr_affiliation_cd:
              filterInsertOfficer?.position_mbr_affiliation_cd,
            aff_pk: affPk,
            pos_start_dt: dayjs(officer.election_date).unix(),
            pos_expiration_dt: dayjs(officer.expiration_date).unix(),
            person_pk: officer.enterprise_ID,
            office_uuid: office.office_uuid,
          };
          return officer;
        }
      });

      return await Promise.all(promises);
    } catch (error) {
      console.log(error);
    } finally {
      if (status) setLoading(false);
    }
  }, []);

  const handleSubmitAll = () => {
    setLoading(true);
    setIsSubmit(true);

    const promises = dataOfficer
      .filter((el) => el.needs_update || el.needs_insert)
      .filter((el) => !el.isSubmit)
      .filter((el) => !el.errors.length)
      .map((officer) => handleSubmit(officer, false));

    Promise.all(promises).finally(() => {
      setIsSubmit(false);
      setLoading(false);
    });
  };

  const removeErrorOfficer = () => {
    let dataRemove = dataOfficer;

    dataRemove = dataRemove.filter((item) => {
      return !item.isError && !item.errors.length;
    });
    return dataRemove;
  };

  const handleRejectAll = () => {
    setDataOfficer(removeErrorOfficer);
  };

  return (
    <Layout>
      <Loading isVisible={loading} />
      <ToastContainer />

      <div className="mx-auto px-10 mb-32">
        <div className="flex flex-wrap gap-2 justify-start md:p-8">
          <div onClick={handleImport}>
            <Button text={"Import"} color={"bg-green-10"} />
          </div>
          <div onClick={handleBack}>
            <Button text={"Back"} color={"bg-red-500"} />
          </div>
        </div>
        {/* table */}
        <div className="space-y-20 mt-6">
          {dataOfficer?.length > 0 && (
            <div>
              <div className="overflow-auto">
                <TableOfficer
                  officers={dataOfficer}
                  setDataOfficer={setDataOfficer}
                  handleSubmit={handleSubmit}
                />
              </div>
              <div className="flex gap-3">
                {dataOfficer.some((el) => !el.errors.length && !el.isSubmit) ? (
                  <div className="mt-5" onClick={handleSubmitAll}>
                    <Button
                      text={"Submit all"}
                      color={isSubmit ? "bg-slate-400" : "bg-green-10"}
                      disabled={isSubmit}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {dataOfficer.some((el) => el.errors.length) ? (
                  <div className="mt-5" onClick={handleRejectAll}>
                    <Button
                      text={"Reject all"}
                      color={isSubmit ? "bg-slate-400" : "bg-red-500"}
                      disabled={isSubmit}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {showUploadModal && (
        <Modal closeModal={closeUploadModal} title="Upload excel file">
          <ModalUploadFile
            closeModal={closeUploadModal}
            setDataOfficer={setDataOfficer}
            handleLoadingFile={handleLoadingFile}
            setLoading={setLoading}
          />
        </Modal>
      )}
    </Layout>
  );
}

export default ImportOfficer;
