import * as yup from "yup";
import {
  REQUIRED_EMAIL,
  REQUIRED_FIELD,
  REQUIRED_INTEGER,
  REQUIRED_POSITIVE,
} from "utils/constants";

export const schemaSignUp = yup
  .object({
    firstName: yup.string(),
    lastName: yup.string(),
    email: yup.string().email(REQUIRED_EMAIL).required(REQUIRED_FIELD),
    phone: yup.string().required(REQUIRED_FIELD),
  })
  .required();

export const schemaSignIn = yup
  .object({
    userName: yup
      .string()
      .email("Email must be a valid email")
      .required("Please enter the required field"),
    password: yup.string().required("Please enter the required field"),
  })
  .required();

export const schemaNewPassword = yup.object({
  newPassword: yup.string().required(REQUIRED_FIELD),
  newPasswordVerify: yup.string().required(REQUIRED_FIELD),
});

export const schemaAddOfficer = yup.object({
  first_nm: yup.string().required(REQUIRED_FIELD),
  last_nm: yup.string().required(REQUIRED_FIELD),
  person_pk: yup.string().required(REQUIRED_FIELD),
});

export const schemaTransmittal = yup.object({
  start_date: yup.string().required(REQUIRED_FIELD),
  end_date: yup.string().required(REQUIRED_FIELD),
});

export const editModalExcel = yup.object({
  state: yup.string().required(REQUIRED_FIELD).trim(),
  aff_type: yup.string().required(REQUIRED_FIELD).trim(),
  officer_title: yup.string().required(REQUIRED_FIELD).trim(),
  officer_name: yup.string().required(REQUIRED_FIELD).trim(),
  enterprise_ID: yup.string().required(REQUIRED_FIELD).trim(),
  election_date: yup.string().required(REQUIRED_FIELD).trim(),
});

export const schemaChangePassword = yup.object({
  oldPassword: yup.string().required(REQUIRED_FIELD),
  newPassword: yup.string().required(REQUIRED_FIELD),
  confirmPassword: yup.string().required(REQUIRED_FIELD),
});

export const schemaModalTitle = yup.object({
  affiliate_title: yup.string().required(REQUIRED_FIELD),
  seats: yup
    .number()
    .typeError(REQUIRED_FIELD)
    .required(REQUIRED_FIELD)
    .positive(REQUIRED_POSITIVE)
    .integer(REQUIRED_INTEGER),
});
