import axios from "axios";
import { Auth } from "aws-amplify";

// axios instance for making requests
const axiosInstance = axios.create();
let requestConfig = null;
// request interceptor for adding token
axiosInstance.interceptors.request.use(
  async (config) => {
    // add token to request headers
    requestConfig = { ...config };
    try {
      config.headers.Authorization = (
        await Auth.currentSession()
      ).idToken.jwtToken;
    } catch (e) {
      await Auth.signOut();
      console.log("error:", e);
    }
    return config;
  },
  function (error) {
    // Do something with request error
    // return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log("🚀 ===== axios response error", {
      error: error.message,
      url: requestConfig.url,
      data: requestConfig.data,
    });
    return Promise.reject(error);
  }
);

export default axiosInstance;
