import React from "react";

function EditBox({ text, textColor, bgColor, border, handleEditOfficerTitle }) {
  return (
    <div
      className={`flex items-center w-fit rounded-lg h-5 mt-0.5 cursor-pointer hover:opacity-80`}
      onClick={handleEditOfficerTitle}
    >
      <p className={`px-2 text-[12px] ${textColor} truncate text-decorate underline`}>{text}</p>
    </div>
  );
}

export default EditBox;
