import { Button } from "components/Button";
import React from "react";
import { useForm } from "react-hook-form";
import { dirtyValues, formatDate } from "utils/helper";
import dayjs from "dayjs";
import { editOfficer } from "services/officer";
import { notifyError } from "utils/helper";

function EditModal({ data, closeModal, getDataOfficer }) {
  const {
    register,
    handleSubmit,
    formState: { dirtyFields },
  } = useForm();
  console.log(data);
  const onSubmit = async (submitData) => {
    try {
      console.log(submitData);
      let dirtiedValues = dirtyValues(dirtyFields, submitData);

      dirtiedValues.uuid = submitData.uuid;
      dirtiedValues.aff_pk = +submitData.aff_pk;
      if (dirtiedValues.pos_expiration_dt) {
        dirtiedValues.pos_expiration_dt = dayjs(
          dirtiedValues.pos_expiration_dt
        ).unix();
      }

      if (dirtiedValues.suspended_dt) {
        dirtiedValues.suspended_dt = dayjs(dirtiedValues.suspended_dt).unix();
      }

      if (dirtiedValues.pos_end_dt) {
        dirtiedValues.pos_end_dt = dayjs(dirtiedValues.pos_end_dt).unix();
      }

      if (dirtiedValues.pos_start_dt) {
        dirtiedValues.pos_start_dt = dayjs(dirtiedValues.pos_start_dt).unix();
      }
      if (dirtiedValues.address) {
        dirtiedValues.address = submitData.address;
      }
      console.log(
        "🚀 ~ file: EditModal.js ~ line 39 ~ onSubmit ~ dirtiedValues",
        dirtiedValues
      );

      await editOfficer({
        data: [dirtiedValues],
      });
      getDataOfficer();
      closeModal();
    } catch (error) {
      console.log(
        "🚀 ~ file: EditModal.js ~ line 32 ~ onSubmit ~ error",
        error
      );
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-slate-200/70 p-5 sm:px-10 rounded-b-md overflow-auto max-w-2xl">
        <input
          className="hidden"
          defaultValue={data?.uuid}
          {...register("uuid")}
        />
        <input
          className="hidden"
          defaultValue={data?.aff_pk}
          {...register("aff_pk")}
        />
        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">First Name:</p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            defaultValue={data?.first_nm}
            disabled
            {...register("first_nm")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">Last Name:</p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            defaultValue={data?.last_nm}
            disabled
            {...register("last_nm")}
          />
        </div>
        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">Member ID:</p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            defaultValue={data?.person_pk}
            disabled
            {...register("person_pk")}
          />
        </div>
        {/* <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">Active Flag:</p>
          <input
            type={"checkbox"}
            className="border w-full h-5 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            defaultChecked={data?.active_fg ? true : false}
            {...register("active_fg")}
          />
        </div> */}

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">Start:</p>
          <input
            type="date"
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            defaultValue={dayjs.unix(data?.pos_start_dt).format("YYYY-MM-DD")}
            {...register("pos_start_dt")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">End:</p>
          <input
            type="date"
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            defaultValue={
              data?.pos_end_dt
                ? dayjs.unix(data?.pos_end_dt).format("YYYY-MM-DD")
                : null
            }
            // value={data?.pos_end_dt}
            // value="2018-07-22"
            {...register("pos_end_dt")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">Expiration:</p>
          <input
            type="date"
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            defaultValue={
              data?.pos_expiration_dt
                ? dayjs.unix(data?.pos_expiration_dt).format("YYYY-MM-DD")
                : null
            }
            // value="2018-07-22"
            {...register("pos_expiration_dt")}
          />
        </div>

        {/* <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">Suspended Flag:</p>
          <input
            type={"checkbox"}
            className="border w-full h-5 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            defaultChecked={data?.suspended_fg ? true : false}
            {...register("suspended_fg")}
          />
        </div> */}

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">Suspended:</p>
          <input
            type="date"
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            defaultValue={
              data?.suspended_dt
                ? dayjs.unix(data?.suspended_dt).format("YYYY-MM-DD")
                : null
            }
            // value="2018-07-22"
            {...register("suspended_dt")}
          />
        </div>

        {/* <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">Temporary Reporting Officer :</p>
          <input
            type={"checkbox"}
            className="border w-full h-5 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            defaultChecked={data?.reporting_officer_fg}
            {...register("reporting_officer_fg")}
          />
        </div> */}

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">Address1: </p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            defaultValue={data?.address.Addr1}
            {...register("address.Addr1")}
          />
        </div>
        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">Address2: </p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            defaultValue={data?.address.Addr2}
            {...register("address.Addr2")}
          />
        </div>
        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">City: </p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            defaultValue={data?.address.City}
            {...register("address.City")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">State: </p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            defaultValue={data?.address.State}
            {...register("address.State")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">Zipcode: </p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            defaultValue={data?.address.Zipcode}
            {...register("address.Zipcode")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">Phone:</p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            defaultValue={data?.phone}
            {...register("phone")}
          />
        </div>
        {data?.last_modified_date && (
          <div className="flex">
            <span className="text-sm italic flex-1 text-right">
              Last modified by <b>{data?.last_modified_by || "system"}</b> on{" "}
              {formatDate(data?.last_modified_date * 1000)}
            </span>
          </div>
        )}
        <div className="flex justify-end space-x-8 mt-4">
          <div onClick={closeModal}>
            <Button text={"Cancel"} color={"bg-red-500"} />
          </div>
          <button
            type="submit"
            className={`bg-blue-500 py-1 sm:py-1.5 px-4 text-white rounded-md text-sm min-w-[90px] hover:opacity-80 shadow-lg duration-500`}
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );
}

export default EditModal;
